




































































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import StringTool from "@/services/tool/StringTool";
import Banner, {BannerEnum, BannerReference} from "@/models/Banner";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import BannerService from "@/services/BannerService";
import FilePickerComponent from "@/components/FilePickerComponent.vue";
import LangModule from "@/store/LangModule";

@Component({components: {FilePickerComponent}})
export default class BannersView extends Vue {

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	banners: Banner[] = []
	page: number = 1
	size: number = 10
	pageCount: number = 0
	loading: boolean = false
	active: boolean = true
	search: string = ""
	banner: Banner = new Banner()
	reference: BannerReference | null = null

	headers = [
		// { align: "center", text: "Id", value: "id", width: "auto"},
		{ align: "center", text: this.lang.name, value: "name", width: "auto"},
		{ align: "center", text: "Url", value: "url", width: "auto"},
		{ align: "center", text: this.lang.reference, value: "reference", width: "auto"},
		{ align: "center", text: this.lang.active, value: "active", width: "100px"},
	]

	bannerReferences = Object.keys(require(`@/models/Banner`).BannerReference)

	dialog: boolean = false
	newBannerActive: boolean = false
	image: File | null = null

	urlRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.urlRequired,
		(v: string) => StringTool.validateUrl(v) ? true : this.lang.urlNoValid
	]

	created() {
		this.refresh()
	}

	async refresh() {
		await BannerService.findBanners(this, this.banners, this.page - 1, this.size, this.active, this.reference)
	}

	typeToName(type: BannerEnum): string {
		return BannerService.typeToName(type)
	}

	referenceToName(reference: BannerReference): string {
		return BannerService.referenceToName(reference)
	}

	rowClick(banner: Banner) {
		this.$router.push({path: "/banners/" + banner.id})
	}

	openUrl(url: string) {
		window.open(url, "_blank");
	}

	openCreateDialog() {
		if (this.form) this.form.resetValidation()
		this.dialog = true
		this.banner = new Banner()
		this.banner.active = true
	}

	createBanner() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
					this.lang.warning,
					this.lang.bannerCreateQuestion,
					async () => {
						await BannerService.postBanner(this, this.banner, this.image!)
					}
			))
		}
	}

	@Watch("reference")
	@Watch("active")
	onFiltersChanged() {
		this.refresh()
	}

}
